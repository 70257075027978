<!--选择充值通道组件封装-->
<template>
  <div>
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="channelValue"
        :options="channelList"
        @change="chooseChannel"
      ></van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      channelValue: "0", //选中的充值通道信息
      channelList: [
        {
          text: "请选择充值通道",
          value: "0"
        },
        {
          value: "APP",
          text: "app"
        },
        {
          value: "WE_CHAT",
          text: "微信付款码"
        },
        {
          value: "ALIPAY",
          text: "支付宝"
        },
        {
          value: "GONGSHANG_BANK",
          text: "中国工商银行"
        },
        {
          value: "HANG_ZHOU_BANK",
          text: "杭州银行付款码"
        },
        {
          value: "PETTY_PASS",
          text: "小额通"
        }
      ] //客服列表
    };
  },
  methods: {
    //选择客服
    chooseChannel(value) {
      this.channelValue = value;
      this.$emit("channelValue", this.channelValue);
    }
  }
};
</script>
