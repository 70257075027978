import Vue from 'vue'
// import { EVENTS_MAP } from '@/common/eventBus'
// import { base, pydataBase, qiniuBase } from '@api/index'
// const root_url =  'https://api.dev.farbun.com'
const root_url = process.env.NODE_ENV !== "production" ? "https://api.dev.farbun.com" : "https://api.farbun.com";
const data_url = process.env.NODE_ENV !== "production" ? "https://data.dev.farbun.com" : "https://data.farbun.com";
const pay_url="https://api.mch.weixin.qq.com/v3/pay"
// const BASE_URL =
//   process.env.VUE_APP_BUILD === 'https://api.farbun.com'
//     ? 'https://data.farbun.com'
//     : 'https://data.dev.farbun.com'
// const fissionURL =  process.env.VUE_APP_BUILD === 'https://api.farbun.com'
// ? 'https://fission.farbun.com'
// : 'https://fission.dev.farbun.com' 
/**
 * 验证
 * @type 类型
 * @value 内容
 */
const test = (type, value) => {
  let result = false
  let reg = ''
  switch (type) {
    // 邮箱
    case 'email':
      reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      result = reg.test(value)
      break
    // 手机号
    case 'phone':
      reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      result = reg.test(value)
      break
    case 'cardId':
      reg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/
      result = reg.test(value)
      break
    // QQ号码
    case 'QQ':
      reg = /^[0-9]*$/
      result = reg.test(value)
      break
    // 固定电话
    case 'telephone':
      if (!isNaN(value.replace(/-/g, ''))) {
        if (value.length <= 12) {
          if (value.length === 8) {
            result = !isNaN(value)
          } else if (value.length === 11) {
            // 如果小于等于12位，11位数字或者11位数字包含-
            result = !isNaN(value)
          } else if (value.length === 12) {
            if (value.split('-').length === 2) {
              result = true
            } else {
              result = false
            }
          } else {
            result = false
          }
        } else if (value.length >= 13 && value.length <= 17) {
          if (isNaN(value)) {
            // 不全是数字
            if (value.length >= 14 && value.length <= 16) {
              if (
                value.split('-').length === 2 ||
                value.split('-').length === 3
              ) {
                result = true
              } else {
                result = false
              }
            } else if (value.length === 17) {
              if (value.split('-').length === 3) {
                result = true
              } else {
                result = false
              }
            } else {
              result = false
            }
          } else {
            // 全是数字
            if (value.length >= 13 && value.length <= 15) {
              result = true
            } else {
              result = false
            }
          }
        } else {
          result = false
        }
      } else {
        result = false
      }
      break
  }
  return result
}
const formatDate = (fmt, date) => {
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
      )
    }
  }
  return fmt
}
Vue.prototype.$test = test
Vue.prototype.$formatDate = formatDate
Vue.prototype.$base = root_url
Vue.prototype.$pay =pay_url
Vue.prototype.$dataUrl =data_url
// Vue.prototype.$pydataBase = BASE_URL
// export { root_url, BASE_URL, fissionURL }
export { root_url,pay_url,data_url }