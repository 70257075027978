<template>
  <div>
    <van-uploader
      v-model="fileList"
      v-bind:after-read="afterRead"
      :max-count="1"
      upload-text="请上传小于2M图片"
      v-bind:oversize="oversize"
    />
  </div>
</template>
<script>
export default {
  props: { userId: Number },
  data() {
    return {
      fileList: []
    };
  },
  methods: {
    //加载完成之后
    afterRead(event) {
      let formData = new FormData();
      //文件上传至服务器
      formData.append("file", event.file);
      formData.append("dataType", "1");
      formData.append("id", this.userId);
      this.$axios
        .post(`${this.$base}/fission/obs/upload`, formData)
        .then((res) => {
          if (res.code === "200") {
            this.$toast("上传成功");
            this.fileList = [];
            this.fileList.push({
              url: res.data.url,
              name: "充值图片",
              isImage: true
            });
            this.$emit("imageUrl", res.data.url);
          }
        });
    },
    //文件读取完触发
    oversize() {
      this.$toast("请注意文件大小!");
    }
  }
};
</script>
