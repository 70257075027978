<template>
  <div>
    <van-area
      :area-list="areaList"
      :columns-placeholder="['请选择', '请选择', '请选择']"
      title="所在区域选择"
      @cancel="cancel"
      @confirm="getAreaData"
    />
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      areaList,
      area: "",
      chooseArea: false
    };
  },
  methods: {
    //地区选择确认
    getAreaData(data) {
      data.forEach((item) => {
        this.area += item.name;
      });
      this.chooseArea = false;
      this.$emit("chooseArea", this.chooseArea);
      this.$emit("areaData", this.area);
    },
    cancel() {
      this.chooseArea = false;
    }
  }
};
</script>
