import axios from 'axios'
import Cookie from 'js-cookie'
import qs from 'qs'
// 创建一个axios实例
const service = axios.create({
  // 请求超时时间
  // timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
  // baseURL: process.env.VUE_APP_BUILD || 'https://api.farbun.com'
})

// 添加请求拦截器
service.interceptors.request.use(
  config => {
    if (Cookie.get('token')) {
      config.headers['Authentication-Token'] = JSON.parse(Cookie.get('token'))
    }
  //只针对get方式进行序列化
  if (config.method === 'get') {
    config.paramsSerializer = function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
    return config
  },
  err => {
    console.log(err)
  }
)

  
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 401) {
      Cookie.remove('token')
      location.reload
    } else {
      return res
    }
  },
  err => console.log(err)
)

export default service
