<!--选择客服组件封装-->
<template>
  <div>
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="jobValue"
        :options="jobList"
        @change="chooseJob"
      ></van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jobValue: "律师", //选中的职业信息
      jobList: [
        { text: "律师", value: "律师" },
        { text: "专职律师", value: "专职律师" },
        { text: "实习律师", value: "实习律师" },
        { text: "公职律师", value: "公职律师" },
        { text: "其他", value: "其他" }
      ] //职业列表
    };
  },
  methods: {
    //选择职业
    chooseJob(value) {
      this.jobValue = value;
      this.$emit("jobValue", this.jobValue);
    }
  }
};
</script>
