import Vue from "vue"
import App from "./App.vue"
import { router } from './router';
import 'vant/lib/index.css'
import service from './api/config'
import Vant from 'vant';
import 'vant/lib/index.css';
import './util/gloabl.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import customer from './compents/customer.vue'
import area from './compents/area.vue'
import lawFirm from './compents/lawFirm.vue'
import headTabs from './compents/head-tabs.vue'
import sourceType from './compents/sourceType.vue'
import goods from './compents/goods.vue'
import calcTime from './compents/calcTime.vue'
import dayjs from 'dayjs';
import payChannel from './compents/payChannel.vue'
import fileUpload from './compents/fileUpload.vue'
import approveUser from './compents/approveUser.vue'
import jobType from './compents/jobType.vue'
import opener from './compents/opener.vue'
import queryDate from './compents/queryDate.vue'
import province from './compents/province.vue'


Vue.use(Vant);
Vue.use(ElementUI);
Vue.component('customer', customer)
Vue.component('chooseArea', area)
Vue.component('lawFirm', lawFirm)
Vue.component('headTabs', headTabs)
Vue.component('sourceType', sourceType)
Vue.component('goods', goods)
Vue.component('calcTime', calcTime)
Vue.component('payChannel', payChannel)
Vue.component('fileUpload', fileUpload)
Vue.component('approveUser', approveUser)
Vue.component('jobType', jobType)
Vue.component('opener', opener)
Vue.component('queryDate', queryDate)
Vue.component('province', province)

Vue.prototype.$axios = service
Vue.prototype.dayjs = dayjs;
new Vue({
  el: "#app",
  router,
  render: (h) => h(App)
}).$mount()