<template>
  <div>
    <van-button type="default" size="large" @click="show = true">{{
      label
    }}</van-button>
    <van-dialog
      v-model="show"
      title="律所选择"
      :show-confirm-button="false"
      closeOnClickOverlay
      style="max-height: 80%"
    >
      <van-cell-group>
        <van-field
          v-model="lawfirmName"
          label="律所名称"
          placeholder="请输入律所名称"
          @input="queryLawfirm"
        />
        <van-checkbox-group>
          <van-cell-group inset>
            <van-cell
              v-for="(item, index) in lawfirmList"
              :key="index"
              :title="item.text"
              @click="toggle(item)"
              clickable
            />
          </van-cell-group>
        </van-checkbox-group>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
export default {
  props: { direction: String },
  data() {
    return {
      lawfirmValue: 0, //律所id
      lawfirmList: [], //律所列表
      lawfirmName: "",
      show: false,
      label: "选择律所",
    };
  },
  methods: {
    //查询律所
    queryLawfirm() {
      this.lawfirmList = [];
      this.lawfirmValue = 0;
      this.$axios
        .get(
          `${this.$base}/fission/page/queryLwafirm?name=${this.lawfirmName}&offset=0&limit=10`
        )
        .then((res) => {
          if (res) {
            res.forEach((item) => {
              this.lawfirmList.push({
                text: item.name,
                value: item.id,
              });
            });
          } else {
            this.lawfirmList.push({
              text: "暂无该律所信息",
              value: 0,
            });
          }
        });
    },
    //点击单元格触发
    toggle(value) {
      this.label = value.text;
      this.$emit("chooseLaw", value.value);
      this.$emit("lawText", value.text);
      this.show = false;
    },
  },
};
</script>
