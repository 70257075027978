<!--选择客服组件封装-->
<template>
  <div>
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="cusValue"
        :options="customerList"
        @change="chooseCustomer"
      ></van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>

<script>
export default {
  prop: { cusChoose: Number },
  data() {
    return {
      cusValue: 0, //选中的客服信息
      customerList: [] //客服列表
    };
  },
  methods: {
    //加载客服
    loadCustomer() {
      this.customerList.push({
        text: "请选择客服",
        value: 0
      });
      this.$axios
        .get(`${this.$base}/fission/page/queryCustomer`)
        .then((res) => {
          if (res) {
            res.forEach((item) => {
              this.customerList.push({
                text: item.name + "-" + item.mobile,
                value: item.id
              });
            });
          }
        });
    },
    //选择客服
    chooseCustomer(value) {
      this.cusValue = value;
      this.$emit("cusValue", this.cusValue);
    }
  },
  mounted() {
    this.loadCustomer();
  }
};
</script>
