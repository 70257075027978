<!--选择客服组件封装-->
<template>
  <div>
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="approveValue"
        :options="approveList"
        @change="chooseApprove"
      ></van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      approveValue: 0, //选中的审批人信息
      approveList: [] //审批人列表
    };
  },
  methods: {
    //加载客服
    loadApprove() {
      this.approveList.push({
        text: "请选择审批人",
        value: 0
      });
      this.$axios.get(`${this.$base}/admin/user/list`).then((res) => {
        if (res) {
          res.data.forEach((item) => {
            this.approveList.push({
              text: item.nickName,
              value: item.id
            });
          });
        }
      });
    },
    //选择客服
    chooseApprove(value) {
      this.approveValue = value;
      this.$emit("approveValue", this.approveValue);
    }
  },
  mounted() {
    this.loadApprove();
  }
};
</script>
