import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/search'
  },
  {
    name: 'search',
    component: () => import('./views/search/index.vue'),
    meta: {
      title: '短信模板'
    }
  },
  {
    name: 'register',
    component: () => import('./views/register/index.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    name: 'achievement',
    component: () => import('./views/achievement/index.vue'),
    meta: {
      title: '业绩展示'
    }
  },
  {
    name: 'sourceApprove',
    component: () => import('./views/sourceApprove/index.vue'),
    meta: {
      title: '资源审批'
    }
  },
  {
    name: 'changeCustomer',
    component: () => import('./views/changeCustomer/index.vue'),
    meta: {
      title: '申请变更客服'
    }
  },
  {
    name: 'vipApply',
    component: () => import('./views/vipApply/index.vue'),
    meta: {
      title: '申请vip'
    }
  },
  {
    name: 'vipApprove',
    component: () => import('./views/vipApprove/index.vue'),
    meta: {
      title: 'vip审批'
    }
  },
  {
    name: 'sourceApply',
    component: () => import('./views/sourceApply/index.vue'),
    meta: {
      title: '申请资源'
    }
  },
  {
    name: 'vipList',
    component: () => import('./views/vipList/index.vue'),
    meta: {
      title: 'vip申请列表'
    }
  },
  {
    name: 'batchSend',
    component: () => import('./views/batchSend/index.vue'),
    meta: {
      title: '短信多发'
    }
  },
  {
    name: 'cusApprove',
    component: () => import('./views/cusApprove/index.vue'),
    meta: {
      title: '客服变更'
    }
  },
  {
    name: 'clientList',
    component: () => import('./views/clientList/index.vue'),
    meta: {
      title: '我的客户'
    }
  },
  {
    name: 'visitLog',
    component: () => import('./views/visitLog/index.vue'),
    meta: {
      title: '反馈记录'
    }
  },
  {
    name: 'weChatPay',
    component: () => import('./views/weChatPay/index.vue'),
    meta: {
      title: '购买会员'
    }
  },
  {
    name: 'sendMiniApp',
    component: () => import('./views/sendMiniApp/index.vue'),
    meta: {
      title: '发送小程序'
    }
  },
  {
    name: 'payNotify',
    component: () => import('./views/payNotify/index.vue'),
    meta: {
      title: '支付结果'
    }
  },
  {
    name: 'cusRemind',
    component: () => import('./views/cusRemind/index.vue'),
    meta: {
      title: '提醒详情'
    }
  },
  {
    name: 'visitInfo',
    component: () => import('./views/visitInfo/index.vue'),
    meta: {
      title: '跟进详情'
    }
  },
  {
    name: 'commission',
    component: () => import('./views/commission/index.vue'),
    meta: {
      title: '优惠券详情'
    }
  },
  {
    name: 'cusApprise',
    component: () => import('./views/cusApprise/index.vue'),
    meta: {
      title: '服务评价'
    }
  },
  {
    name: 'searchHis',
    component: () => import('./views/searchHis/index.vue'),
    meta: {
      title: '历史记录'
    }
  },
  
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};