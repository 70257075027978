<template>
  <div>
    <van-cell-group class="vanCellGroupClass">
      <van-cell :title="title" :label="label">
        <template value="title">
          <van-stepper
            :integer="true"
            style="display: inline"
            v-model="goods"
            @plus="add"
            @minus="minus"
            @change="onchange"
            :min="0"
          />
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
export default {
  props: { title: String, label: String },
  data() {
    return {
      goods: 0, //计数器
    };
  },
  methods: {
    add() {
      this.$emit("add", this.goods + 1);
    },
    minus() {
      this.$emit("minus", this.goods - 1);
    },
    onchange(val) {
      this.$emit("change", val);
      this.$emit("title", { title: this.title, value: val });
    },
  },
};
</script>
