<template>
  <div>
    <van-row>
      <van-col span="10"
        ><van-field
          v-model="startTime"
          placeholder="开始时间"
          readonly
          :border="false"
          @click-input="pickStartTime"
      /></van-col>
      <van-col span="4"><van-field v-model="text" readonly /></van-col>
      <van-col span="10"
        ><van-field
          v-model="endTime"
          placeholder="结束时间"
          readonly
          :border="false"
          @click-input="pickEndTime"
      /></van-col>
    </van-row>
    <van-datetime-picker
      type="date"
      v-model="currentDate"
      :min-date="minDate"
      :max-date="maxDate"
      @input="onStartInput"
      v-if="showStartDate"
      title="开始时间选择"
      @cancel="startCancel"
      @confirm="startConfirm"
    />
    <van-datetime-picker
      type="date"
      v-model="currentDate"
      :min-date="minDate"
      :max-date="maxDate"
      @input="onEndInput"
      v-if="showEndDate"
      title="结束时间选择"
      @cancel="endCancel"
      @confirm="endConfirm"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      startTime: "", //开始时间
      endTime: "", //结束时间
      text: "至",
      showStartDate: false,
      currentDate: new Date(), //默认展示日期
      minDate: new Date("2020-01-01 00:00:00"), //最小日期
      maxDate: new Date("2110-12-31 00:00:00"), //最大日期
      showEndDate: false,
    };
  },
  methods: {
    //选择开始时间
    pickStartTime() {
      this.showStartDate = true;
    },
    startCancel() {
      this.startTime = "";
      this.showStartDate = false;
    },
    startConfirm() {
      this.showStartDate = false;
      this.$emit("startResult", this.startTime);
    },
    //选择开始时间时触发
    onStartInput(val) {
      this.startTime = this.dayjs(val).format("YYYY-MM-DD hh:mm:ss");
    },
    //选择结束时间
    pickEndTime() {
      this.showEndDate = true;
    },
    endCancel() {
      this.endTime = "";
      this.showEndDate = false;
    },
    endConfirm() {
      this.showEndDate = false;
      this.$emit("endResult", this.endTime);
    },
    //选择结束时间时触发
    onEndInput(val) {
      this.endTime = this.dayjs(val).format("YYYY-MM-DD hh:mm:ss");
    },
  },
  mounted() {},
};
</script>
