<template>
  <div>
    <van-field
      placeholder="请输入充值月数"
      v-model="changeValue"
      label="充值月数"
      type="number"
      @input="chooseMonth"
      @blur="regMonth"
    />
    <van-field v-model="showMonth" label="会员到期时间" readonly />
  </div>
</template>
<script>
export default {
  props: { label: String, dateValue: String },
  data() {
    return {
      showMonth: this.dayjs().format("YYYY-MM-DD"),
      changeValue: "",
    };
  },
  methods: {
    chooseMonth() {
      if (!this.dateValue) {
        this.$toast("请先输入账号!");
        return;
      }
      this.showMonth = this.dayjs(this.dateValue)
        .add(this.changeValue, "month")
        .format("YYYY-MM-DD");
      this.$emit("result", this.showMonth);
      this.$emit("change", this.changeValue);
    },
    regMonth() {
      if (this.changeValue % 1 != 0) {
        this.$toast("充值金额请输入整数");
      }
    },
  },
};
</script>
