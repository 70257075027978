<template>
  <div>
    <van-row>
      <van-col span="12"
        ><van-field
          v-model="province"
          placeholder="省"
          readonly
          :border="false"
          @click-input="pickProvince"
      /></van-col>
      <van-col span="12"
        ><van-field
          v-model="city"
          placeholder="市"
          readonly
          :border="false"
          @click-input="pickProvince"
      /></van-col>
    </van-row>
    <van-area
      :area-list="provinceList"
      :columns-placeholder="['请选择', '请选择']"
      :columns-num="2"
      title="省市选择"
      @cancel="cancel"
      @confirm="getAreaData"
      v-if="chooseArea"
    />
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      provinceList: areaList,
      area: "",
      chooseArea: false,
      areaCode: "",
      province: "",
      city: ""
    };
  },
  methods: {
    //地区选择确认
    getAreaData(data) {
      console.log(data);
      data.forEach((item) => {
        if (item.code.substring(item.code.length - 4) === "0000") {
          this.province = item.name;
        } else {
          this.city = item.name;
        }
      });
      this.$emit("province", this.province);
      this.$emit("city", this.city);
      this.chooseArea = false;
    },
    cancel() {
      this.chooseArea = false;
    },
    pickProvince() {
      this.chooseArea = false;
      this.chooseArea = true;
    }
  }
};
</script>
