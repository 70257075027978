<template>
  <van-dropdown-menu active-color="#1989fa">
    <van-dropdown-item
      :value="sourceValue"
      :options="sourceList"
      @change="chooseSource"
    />
  </van-dropdown-menu>
</template>

<script>
export default {
  data() {
    return {
      sourceValue: 0, //资源类别
      sourceList: [] //资源列表
    };
  },
  methods: {
    //选择律所
    chooseSource(value) {
      this.sourceValue = value;
      this.$emit("chooseSource", this.sourceValue);
    }
  },
  mounted() {
    this.sourceList.push(
      { value: 0, text: "请选择资源类型" },
      { value: 1, text: "大数据检索" },
      { value: 2, text: "案情法律机器人" },
      { value: 3, text: "日历" },
      { value: 4, text: "案件" },
      { value: 6, text: "语音转文字" },
      { value: 7, text: "高级语音转文字" },
      { value: 5, text: "网盘" }
    );
  }
};
</script>
