<template>
  <van-dropdown-menu active-color="#1989fa">
    <van-dropdown-item
      ref="lawfirm"
      :value="applyValue"
      :options="applyList"
      @change="chooseApply"
    />
  </van-dropdown-menu>
</template>

<script>
export default {
  data() {
    return {
      applyValue: "",
      applyList: []
    };
  },
  methods: {
    //查询律所
    onload() {
      this.applyList = [];
      this.applyList.push({
        text: "请选择开通人",
        value: ""
      });
      this.applyValue = "";
      this.$axios
        .get(`${this.$base}/admin/user/page?pageSize=20`)
        .then((res) => {
          if (res.code === 200) {
            res.data.data.forEach((item) => {
              this.applyList.push({
                text: item.nickname,
                value: item.nickname
              });
            });
          } else {
            this.applyList.push({
              text: "暂无该开通人信息",
              value: 0
            });
          }
        });
    },
    chooseApply(value) {
      this.applyValue = value;
      this.$emit("chooseApply", this.applyValue);
    }
  },
  mounted() {
    this.onload();
  }
};
</script>
