<template>
  <van-tabs @click="tabClick" color="#1989fa" :swipeable="true">
    <van-tab
      :title="item.text"
      :name="item.value"
      v-for="(item, index) in tagsList"
      :key="index"
    ></van-tab>
  </van-tabs>
</template>

<script>
export default {
  data() {
    return {
      jobType: "律师",
      tagsList: [
        {
          text: "律师",
          value: "律师"
        },
        {
          text: "法律工作者",
          value: "法律工作者"
        },
        {
          text: "其他",
          value: "其他"
        }
      ] //tab头数据
    };
  },
  methods: {
    tabClick(name) {
      this.jobType = name;
      this.$emit("name", this.jobType);
    }
  }
};
</script>
